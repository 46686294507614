<template>
    <div>
        <el-form :model="ruleForm" :rules="rules" ref="formCheck" label-width="110px" class="login-form">
            <div class="zt-block">
                <div class="zt-block-head">我方账户信息</div>
                <div style="padding:30px;">
                    <div style="background:#FAFAFA;padding:20px 0;">
                        <el-form-item prop="sendInfo.name" label="企业名称">
                            <template v-if="comp.name">{{comp.name}}</template>
                            <el-input v-else v-model="ruleForm.sendInfo.name" style="max-width:240px;" placeholder="请填写企业名称"></el-input>
                        </el-form-item>
                        <el-form-item prop="sendInfo.account" label="我方银行账号">
                            <!-- <template v-if="comp.account">{{comp.account}}</template> -->
                            <el-input v-model="ruleForm.sendInfo.account" style="max-width:240px;" placeholder="请填写银行账号"></el-input>
                        </el-form-item>
                        <el-form-item prop="sendInfo.bank_start" label="银行名称">
                            <!-- <template v-if="comp.bank_name">{{comp.bank_name}}</template> -->
                            <el-input style="max-width:240px;" placeholder="请填写银行名称" v-model="ruleForm.sendInfo.bank_start"></el-input>
                        </el-form-item>
                        <el-form-item prop="sendInfo.bank_end" label="开户行支行">
                            <!-- <template v-if="comp.bank_subbranch">{{comp.bank_subbranch}}</template> -->
                            <el-input v-model="ruleForm.sendInfo.bank_end" style="max-width:240px;" placeholder="请填写开户行支行"></el-input>
                        </el-form-item>
                        <el-form-item v-if="payCode.order_type!='bq_order'" prop="amount" label="付款金额">
                            <el-input v-model="ruleForm.amount" style="max-width:240px;" disabled type="number" min="0" placeholder="请输入付款金额"></el-input>
                        </el-form-item>
                    </div>
                </div>
            </div>
            <div class="zt-block">
                <div class="zt-block-head">对方账户信息</div>
                <div style="padding:20px;">
                    <div style="background:#FAFAFA;padding:20px 0;">
                        <el-form-item prop="receivInfo.account" label="对方银行账号">
                            <el-input v-model="ruleForm.receivInfo.account" placeholder="请填写银行账号" style="max-width:240px;"></el-input>
                        </el-form-item>
                        <el-form-item prop="receivInfo.bank" label="银行名称">
                            <el-input v-model="ruleForm.receivInfo.bank" placeholder="请填写银行名称" style="max-width:240px;"></el-input>
                        </el-form-item>
                    </div>
                </div>
            </div>
            <div class="zt-block">
                <div style="padding:20px;" class="UploadImgs">
                    <el-form-item prop="proofImg" label="传付款凭证或截图" label-width="140px">
                        <UploadImgss v-model="ruleForm.proofImg" prefix='pay/proof'></UploadImgss>
                    </el-form-item>
                </div>
            </div>
            <div class="zt-block">
                <div style="max-width:450px;margin:0 auto;padding:20px 0;" class="flex flex-justify-around">
                    <el-button @click="back()" style="width: 80px;" type="primary" plain>取消</el-button>
                    <el-button @click="submitForm('ruleForm')" style="width: 80px;" type="primary">提交</el-button>
                </div>
            </div>
        </el-form>
        
    </div>
</template>
<script>
    import _ from 'lodash';
    import { IMG_URL_PRE } from '@/config';
    import { payPurOrder,offlinePay,offlinePayBq } from '@/service/pay';
    import { getCompBaseInfoById } from '@/service/company';
    import UploadImgss from '@/components/upload/UploadImgss.vue';
    export default {
        components: {
            UploadImgss
        },
        props:['payCode'],
        data() {
            return {
                rules: {
                    [`sendInfo.name`]: [{ required: true, message: '请填写企业名称', trigger: 'blur' }],
                    [`sendInfo.account`]: [{ required: true, message: '请填写银行账号', trigger: 'blur' }],
                    [`sendInfo.bank_start`]: [{ required: true, message: '请填写银行名称', trigger: 'blur' }],
                    [`sendInfo.bank_end`]: [{ required: true, message: '请填写开户行支行名称', trigger: 'blur' }],
                    [`receivInfo.bank`]: [{ required: true, message: '请填写银行名称', trigger: 'blur' }],
                    [`receivInfo.account`]: [{ required: true, message: '请填写银行账号', trigger: 'blur' }],
                    amount: [{ required: true, message: '请输入付款金额', trigger: 'blur' }],
                    proofImg: [{ required: true, message: '请上传付款凭证或截图', trigger: 'blur' }],
                },
                comp:null,
                factoryComp:null,
                ruleForm:{
                    // order_no:null,
                    // bizType:this.payCode.order_type,
                    amount:null,
                    sendInfo:{
                        bank_start:"",
                        bank_end:"",
                        account:"",
                        name:"",
                    },
                    receivInfo:{
                        bank:"",
                        account:"",
                    },
                    proofImg:null,
                },
                order:null,
            }
        },
        async created() {
            this.comp = this.$store.state.loginUser.comp;
            this.factoryComp = await getCompBaseInfoById(this.$route.query.comp_id);
            this.ruleForm.id = this.payCode.id;
            if (this.factoryComp.bank_name) {
                this.ruleForm.receivInfo.bank=this.factoryComp.bank_name;
            }
            if (this.factoryComp.bank_name) {
                this.ruleForm.receivInfo.account=this.factoryComp.bank_id_code;
            }
            if (this.comp.name) {
                this.ruleForm.sendInfo.name=this.comp.name;
            }
            if (this.payCode) {
                this.ruleForm.amount = Math.round(this.payCode.amount * 100) / 100;
            }
        },
        mounted() {},
        methods: {
            back() {
                this.$router.go(-1);
            },
            submitForm() {
                this.$refs['formCheck'].validate((valid) => {
                    if (valid) {
                        this.submit();
                    }
                });
            },
            submit() {
                let _item = _.clone(this.ruleForm);
                _item.sendInfo = {
                    bank:_item.sendInfo.bank_start+","+_item.sendInfo.bank_end,
                    account:_item.sendInfo.account,
                    name:_item.sendInfo.name,
                }
                offlinePay(_item).then(rst => {
                    this.$message.success("提交成功");
                    this.back();
                    // if (this.payCode.bizType=="dy_order") {
                    //     this.$router.push("/factory/demandOrder");
                    // } else if (this.payCode.bizType=="pur_order") {
                    //     this.$router.push("/sale/pur/cgdd");
                    // } else if (this.payCode.bizType=="bq_order") {
                    //     this.$router.push("/factory/qygl");
                    // }
                    console.log(rst);
                }).catch(err => {
                    console.log(err);
                    this.$message.error(err.message);
                });
            },
            
        }
    }
</script>
<style scoped>
    .block {
        padding: 20px 30px;
    }
    .login-form>>> input[type=number] {
        padding: 0 0 0 15px;
    }
</style>